import React,{useState} from 'react'
import Layout from '../components/layout'
import SEO from "../components/seo"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Box, Grid, AspectImage, Badge, Button } from "theme-ui"
import BackgroundImage from 'gatsby-background-image'
import Modal from "react-modal"
import swal from 'sweetalert'
import "../styles/poetry-post.css"


function PoetryPost({data}){
    const poetry = data.allWordpressWpPoetry.edges[0].node
    const [formState, setFormState] = useState({ name:"", email: "", title: poetry.title, content: poetry.content, image: poetry.featured_media.localFile.url});
    const [isOpen, setIsOpen] = useState(false);

    function toggleModal() {
      setIsOpen(!isOpen);
    }

    function encode(data) {
      return Object.keys(data)
          .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
          .join("&")
    }

    const handleChange = (e) => {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }

    // function htmlDecode(input) {
    //   var doc = new DOMParser().parseFromString(input, "text/html");
    //   return doc.documentElement.textContent;
    // }

    const handleSubmit = (event) => {
      event.preventDefault()
      if (formState.name == "" || formState.email == "" || formState.title == "" 
      || formState.name == null || formState.email == null || formState == null ){
        swal("Cancelled", "Please fill in the form", "error")
      }else{
      fetch("/", {
        method: "POST",
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: encode({ 'form-name': "poetryletter", ...formState})
        }).then(() => swal("Good job!", "Thanks for being interested in this poetry! In a short time, you will receive this essay in pdf in your email box.", "success")).catch(error => alert(error))
      }}

      return(
        <Layout>
            <Grid className="poetry-display">
                <Box>
                <h1 dangerouslySetInnerHTML={{__html: poetry.title}}/>
                <Badge style={{float:"center"}}>{poetry.categories[0].name.charAt(0).toUpperCase() + poetry.categories[0].name.slice(1)}</Badge>
                <AspectImage ratio={4/3} src={poetry.featured_media.localFile.publicURL}/>
                <p dangerouslySetInnerHTML={{ __html: poetry.content }}/>
                <Button className="send-me" onClick={toggleModal}>Email a free copy to me</Button>
                <br/>
                </Box>
            </Grid>
            <form  name="poetryletter" data-netlify="true" hidden>
            <input type="text" name="name" />
            <input type="email" name="email" />
            <input type="text" name="title" value={poetry.title} />
            <input type="text" name="content" value={poetry.content} />
            <input type="image" name="image" src={poetry.featured_media.localFile.url} />
            </form>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModal} className="modal">
                <div class="modal-body">
                <form  method="post" name="poetryletter" onSubmit={handleSubmit} className="forms-display">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="poetryletter" value="poetryletter" />
                <h4>Fill in your email so I will send the article to you</h4>
                <p>
                  <label>Title&nbsp;&nbsp;&nbsp;</label>
                  <input className="forms-input" type="text" name="title" value={poetry.title} readonly/>
                  </p>
                  <p>
                  <label>Name&nbsp;</label>
                  <input className="forms-input" type="text" name="name" value={formState.name} onChange={handleChange}/>
                  </p>
                  <p>
                  <label>Email&nbsp;</label>
                  <input className="forms-input" type="email" name="email" value={formState.email} onChange={handleChange}/>
                  </p>
                <p>
                <Button type="submit">Send</Button>
                </p>
                <input  type="hidden" name="content" value={formState.content} onChange={handleChange}/>
                <input  type="hidden" name="image"   value={formState.image} onChange={handleChange}/>
                </form>
                </div>
                <div class="modal-footer">
                <Button style={{right:"10%",  top:"30%", position: "absolute"}} onClick={toggleModal}>X</Button>
                </div>
                </Modal>
            </Layout>
          )
        }

export default PoetryPost

export const query = graphql `
    query($slug: String!){
        allWordpressWpPoetry(filter: {slug: {eq : $slug} }){
            edges {
              node {
                title
                slug
                excerpt
                content
                categories {
                  id
                  name
                }
                featured_media {
                    localFile {
                      publicURL
                      url
                        childImageSharp {
                          fluid(quality:100){
                            ...GatsbyImageSharpFluid
                            ...GatsbyImageSharpFluidLimitPresentationSize
                          }
                          resolutions(width:1000, height: 500) {
                            ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                          }
                        }
                  }
                }
              }
            }
          }
    }
`